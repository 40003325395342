import React, { Component } from 'react';
import './css/style.css';
import movieLister from './image/movielister_top.svg';
import brokenImage from './image/noImage.svg';
import { v4 as uuidv4 } from 'uuid';
import { withCookies, Cookies } from 'react-cookie';
import './Service';
import io from 'socket.io-client';

const socketURL = `wss://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
//const socketURL = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = new WebSocket(socketURL);
    this.socket.timeout = 1000 * 60 * 10; //ms*s*m
    const { cookies } = props;
    const uuid = cookies.get('uuid');
    if (!uuid) {
      cookies.set('uuid', uuidv4());
    }
  }

  state = { connected: false, movies: null, error: false, fullDesc: {}, registered: false };

  componentDidUpdate = () => {
    if ((!this.state.registered && 'serviceWorker' in navigator) || ('serviceWorker' in navigator && this.props.cookies.get('uuid') != this.props.cookies.get('pushUUID'))) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Device successfully registered');
          registration.pushManager.getSubscription().then((subscription) => {
            if (subscription && this.socket.readyState) {
              // Push-Token vom Browser abrufen
              const pushToken = subscription.endpoint;
              const key = subscription.getKey('p256dh');
              const token = subscription.getKey('auth');
              console.log('Register Push Subscription');
              // Push-Token an Ihren Server senden
              const socketData = {
                target: 'DEVICE_PUSH_REGISTRATION',
                data: {
                  pushToken,
                  p256dh: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                  auth: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                  uuid: this.props.cookies.get('uuid'),
                },
              };
              this.socket.send(JSON.stringify(socketData));
              this.props.cookies.set('pushUUID', this.props.cookies.get('uuid'));
              this.setState({ registered: true });
            }
          });
        })
        .catch((error) => console.log(error));
    }
  };

  componentDidMount() {
    console.log(this.socket.readyState);
    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.setState({ connected: true });
    };

    this.socket.onmessage = ({ data }) => {
      this.setState({ movies: JSON.parse(data) });
    };

    this.socket.onerror = (error) => {
      console.log('WebSocket error occured:', error);
      this.setState({ error: true }, () => {
        console.log('Initialize reload');
        setTimeout(() => {
          window.location.reload(false);
        }, 10000);
      });
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
      this.setState({ error: true }, () => {
        console.log('Initialize reload');
        setTimeout(() => {
          window.location.reload(false);
        }, 10000);
      });
    };
  }

  // componentDidMount() {
  //   this.socket.on('connect', () => {
  //     console.log('Socket connected');
  //     this.setState({ connected: true });
  //   });

  //   this.socket.on('movies', (data) => {
  //     console.log('Received movies:', data);
  //     this.setState({ movies: data });
  //   });

  //   this.socket.on('error', (error) => {
  //     console.log('Socket error occurred:', error);
  //     this.setState({ error: true }, () => {
  //       console.log('Initialize reload');
  //       setTimeout(() => {
  //         window.location.reload(false);
  //       }, 10000);
  //     });
  //   });

  //   this.socket.on('disconnect', (reason) => {
  //     console.log('Socket disconnected:', reason);
  //     this.setState({ error: true }, () => {
  //       console.log('Initialize reload');
  //       setTimeout(() => {
  //         window.location.reload(false);
  //       }, 10000);
  //     });
  //   });
  // }

  noImage = (event) => {
    event.target.src = brokenImage;
    event.target.style = 'opacity: 0.1';
  };

  getRatingStars = (value) => {
    let value_ = value.replace(/[a-z,A-Z]/g, '').split('/')[0];
    let starCount = Math.ceil(value_);
    let rating = [];
    for (let i = 1; i <= 10; i++) {
      if (i <= starCount) {
        if (i == starCount) {
          let limit_ = Math.ceil(100 * (value_ - (i - 1)));
          rating.push(
            <div
              key={i}
              className="ratingStar"
              style={{
                background: `var(--p_star_grey)`,
                backgroundImage: `linear-gradient(to right, var(--p_star) ${limit_}%, rgba(0,0,0,0) ${limit_}%)`,
              }}></div>
          );
        } else {
          rating.push(<div key={i} className="ratingStar" style={{ background: `var(--p_star)` }}></div>);
        }
      } else {
        rating.push(<div key={i} className="ratingStar" style={{ background: `var(--p_star_grey)` }}></div>);
      }
    }
    return rating;
  };

  drawNewcard = (movie, key) => {
    let description_ = movie.description;
    if (movie.description.length > 250 && !Object.keys(this.state.fullDesc).filter((el) => `${el}` == `${key}!`).length > 0) {
      description_ = movie.description.substring(0, 250) + '...';
    }
    return (
      <div className="movie" key={key}>
        <div className="left">
          <div className="poster">
            <img src={'/' + movie.cover} alt={movie.title + ' Poster'} onError={this.noImage} />
          </div>
          <div className="info_left">
            <span className="material-symbols-rounded" style={{ color: `var(--s_${movie.quality.sound})` }}>
              volume_up
            </span>
          </div>
        </div>
        <div className="center" style={{ background: `var(--g_${movie.quality.picture})` }}></div>
        <div className="right">
          <div className="title">
            <h1>{movie.title}</h1>
            <div className="imdb">{this.getRatingStars(movie.rating)}</div>
          </div>
          <div className="release">
            <p>{movie.source}</p>
          </div>
          <div className="description">
            <h2>Beschreibung:</h2>
            <p
              onClick={() => {
                if (Object.keys(this.state.fullDesc).filter((el) => `${el}` == `${key}!`).length > 0) {
                  let temp_ = this.state.fullDesc;
                  delete temp_[`${key}!`];
                  this.setState({ fullDesc: temp_ });
                } else {
                  this.setState({
                    fullDesc: { ...this.state.fullDesc, [`${key}!`]: true },
                  });
                }
              }}>
              {description_}
            </p>
          </div>
          <div className="additional">
            <span>
              <b>Genre:</b> {movie.category.replace(',', ', ')}
            </span>
            <span>
              <b>Jahr:</b> {movie.date}
            </span>
            <span>
              <b>Dauer:</b> {movie.length}
            </span>
            <span>
              <b>IMDb:</b> {movie.rating}
            </span>
          </div>
          <div className="info_right">
            <span className="material-symbols-rounded" style={{ color: `var(--s_${movie.quality.sound})` }}>
              volume_up
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="movieContainer">
        <div id="fadeground"></div>
        <div id="top_graphic">
          <img src={movieLister} alt="Logo Grafik" />
        </div>
        <div className="CardContainer" id="list">
          {this.state.movies
            ? this.state.movies.map((movie, key) => {
                return this.drawNewcard(movie, key);
              })
            : ''}
        </div>
      </div>
    );
  }
}

export default withCookies(App);

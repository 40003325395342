if ('Notification' in window && navigator.serviceWorker) {
  Notification.requestPermission().then(function (permission) {
    if (permission === 'granted') {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BFgCWXe0JYbqmglX9ZLxhNYRXnM0K3r0AVjpSwdbREp4Afz2cPYpopZ9GTtG0TtLWn7psPXTbujaL_Pz8WgK2wI'),
          })
          .then(function (subscription) {
            console.log('Subscription successful:', JSON.stringify(subscription));
          })
          .catch(function (error) {
            console.error('Subscription failed', error);
          });
      });
    }
  });
}

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
